.notification-modal {
  position: absolute;
  top: 50px;
  right: 20px;
  width: 300px;
  background-color: black;
  border: 1px solid #34b2fd;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #34b2fd;
}

.notification-item h4 {
  margin: 0;
}

.notification-item p {
  margin: 5px 0;
}

.notification-bell {
  position: relative;
  font-size: 24px;
}

.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* bell icon  */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.mt-2 {
  margin-top: 0.5rem;
}

.w-48 {
  width: 12rem;
}



.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.rounded-md {
  border-radius: 0.375rem;
}

.z-50 {
  z-index: 50;
}
