

.contact-form {
  background-color: #000000;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  border-color: #0380ca;
  border: 2px;
}

.contact-form h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.contact-form p {
  margin-bottom: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
}

.contact-form input, 
.contact-form select {
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  width: 48%; /* Adjusted width for two side-by-side fields */
  background-color: #111;
  color: #fff;
  font-size: 1em;
}

.contact-form select {
  appearance: none; /* Hides the default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="292.4" height="292.4"><path fill="%23fff" d="M287 69.4l-121 121c-4.1 4.1-10.8 4.1-14.9 0l-121-121c-4.1-4.1-4.1-10.8 0-14.9s10.8-4.1 14.9 0l114.6 114.6L272 54.5c4.1-4.1 10.8-4.1 14.9 0s4.1 10.8 0 14.9z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px;
  color: #999;
}

.contact-form input::placeholder {
  color: #999;
}

.contact-form input:focus, 
.contact-form select:focus {
  outline: none;
  color: #999;
}

.contact-form button {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #0380ca;
  color: #111111;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.confirmation-message {
  color: #999; /* Gray color for the main content */
  font-family: Arial, sans-serif;
  text-align: left;
  padding: 20px;
  width: 100%; /* Make the message take up the full width of the screen */
  max-width: 800px; /* Set max-width to avoid too wide text */
  margin: 0 auto; /* Center the message horizontally */
  line-height: 1.6;
  display: grid;
}

.confirmation-message h2 {
  text-align: center; /* Center align the Hello line */
  color: #0380ca; /* Blue color for the Hello line */
  margin-bottom: 20px;
}

.confirmation-message p {
  margin-bottom: 20px; /* Ensure space between paragraphs */
  display: block; /* Ensures each paragraph behaves as a block */
  clear: both; /* Clears any floated elements */
}

.confirmation-message strong {
  color: #fff; /* Emphasize the category and service with white text */
}

body {
  background-color: #000; /* Ensure background matches the form */
}
