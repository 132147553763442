/* Main Leadership Container */
.leadership-container {
  background-color: #000000;
  color: #ffffff;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.leadership-title {
  text-align: center;
  font-size: 2.5rem;
  color: #0380ca;
  margin-bottom: 2rem;
  
}

/* Leaders Wrapper */
.leaders-wrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Leader Item */
.leader-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #0f0f0f;
  padding: 1.5rem;
  border-radius: 8px;
  width: calc(50% - 2rem); /* Two columns on larger screens */
  margin-bottom: 2rem;
  transition: transform 0.3s ease;
  border: 1px solid #0380ca;
  border-radius: 8px;
}

.leader-item:hover {
  transform: scale(1.05);
}

/* Leader Image Container */
.leader-image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.leader-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.leader-image-container.hover {
  border: 4px solid #0380ca;
}

/* Leader Info */
.leader-name {
  font-size: 1.8rem;
  color: #0380ca;
  margin-bottom: 0.5rem;
}

.leader-position {
  font-size: 1.2rem;
  color: #bbbbbb;
  margin-bottom: 1rem;
}

.leader-summary {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-icon {
  width: 24px;
  height: 24px;
  transition: filter 0.3s ease;
}

.social-icon:hover {
  filter: brightness(1.5);
}

/* Responsive Design */
@media (max-width: 768px) {
  .leader-item {
    width: calc(100% - 2rem); /* Full width on tablet */
  }

  .leader-image-container {
    width: 120px;
    height: 120px;
  }

  .leader-name {
    font-size: 1.6rem;
  }

  .leader-position {
    font-size: 1rem;
  }

  .leader-summary {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .leadership-title {
    font-size: 2rem;
  }

  .leader-image-container {
    width: 100px;
    height: 100px;
  }

  .leader-name {
    font-size: 1.4rem;
  }

  .leader-position {
    font-size: 0.9rem;
  }

  .leader-summary {
    font-size: 0.8rem;
  }
}
