/* Chatbot Container */
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  max-width: 100%;
}

/* Chatbot Pop-up */
.chatbot__popup {
  background-color: black;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  border: 2px solid #0386d6;
  margin-bottom: 10px;
  color: silver;
}

.chatbot__popup-text {
  font-size: 14px;
  margin: 0;
}

.chatbot__close-btn {
  background: none;
  border: none;
  color: #0386d6;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

/* Chatbot Icon */
.chatbot__icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
}

/* Chatbot Message Window */
.chatbot__message-window {
  padding: 10px;
  background-color: black;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
}

/* Chatbot Entry */
.chatbot__entry {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
  border-radius: 0 0 10px 10px;
  position: relative;
}

/* Chatbot Input */
.chatbot__input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  background-color: #222;
  color: white;
}

/* Send Button */
.chatbot__send-btn {
  background-color: #0386d6;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.chatbot__send-btn:hover {
  background-color: #026bb5;
}

/* Chatbot Messages */
.is-ai {
  background-color: #0386d6;
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  word-break: break-word;
}

.is-user {
  background-color: silver;
  color: black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  word-break: break-word;
}
