:root {
  --main-bg: #000000;
  --pink: 255, 0, 89;
  --violet: 186, 0, 253;
  --blue: 0, 165, 253;
  --yellow: 255, 200, 0;
  --light: 255, 255, 255;
  --alpha: .5;
}

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--main-bg);
  overflow-x: hidden;
}

.services-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.bubble, .bubble1 {
  transition: all 0.3s ease;
  position: relative;
  border-radius: 50%;
  box-shadow: inset 0 0 2rem rgba(var(--light), 1), 
              inset 2rem 0 4rem rgba(var(--pink), 1),
              inset -2rem 0 6rem rgba(var(--blue), 1),
              inset 0 4rem 4rem rgba(var(--violet), 1),
              inset 0 -2rem 2rem rgba(var(--yellow), 1),
              inset 2rem -2rem 1rem rgba(var(--yellow), 1);
  animation: floating 3s ease-in-out infinite;
  cursor: pointer;
  /* filter: brightness(0.1); */
   /* Reduce brightness to 70% for bubbles only */
}

.bubble {
  width: 22rem;
  height: 22rem;
}

.bubble1 {
  width: 19rem;
  height: 19rem;
}

.bubble.animated, .bubble1.animated {
  animation: rubber 1s linear;
  filter: brightness(0.85); /* Slightly increase brightness on animation */
}

@keyframes rubber {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }
  65% {
    transform: translate(0, 1rem);
  }
  to {
    transform: translate(0, 0);
  }
}

/* Full-Screen Pop-Up */
.fullscreen-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 350px;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1; /* Highest z-index to ensure it appears above everything else */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  color: inherit;
  border-radius: 110px; /* Rounded corners */
  overflow-y: auto; /* Handle overflow if content exceeds popup height */
}

.popup-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.sub-services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  font-family: "Caption", sans-serif;
  color: inherit;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
  list-style: disc; /* Adds bullet points */
  padding-left: 20px; /* Indent for bullets */
}

.sub-services li {
  margin-bottom: 5px;
}

.summary {
  font-size: 1.0rem;
  margin-bottom: 20px;
  text-align: center;
  max-width: 90%;
}

.popup-video {
  width: 80%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.popup-images {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 80%;
}

.popup-image {
  width: calc(50% - 10px); /* Display images in a 2-column grid */
  height: auto;
  border-radius: 8px;
}

/* Media Queries for Smaller Screens */
@media (max-width: 800px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: auto;
    /* padding-left: -5px; */
    margin-left: 2px;
  }

  .flex {
    flex-direction: column;
    gap: 4px;
  }

  .bubble, .bubble1 {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 0 auto;
  }

  .fullscreen-popup {
    width: 90%;
    height: auto;
    padding: 15px;
  }

  .popup-video {
    width: 100%; /* Adjust video size for smaller screens */
  }

  .popup-images {
    width: 100%; /* Adjust image grid width */
  }

  .popup-image {
    width: calc(50% - 5px); /* Adjust image size for smaller screens */
  }
}
