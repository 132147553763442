.services-container {
  width: 100%;
  position: relative;
  text-align: center;
  min-height: calc(100vh - 200px); /* Adjust height to remove excessive space */
}

.services-heading {
  font-family: 'orbitron', sans-serif;
  font-size: 3rem;
  color: #0380ca;
  margin-top: 2rem;
}

.bubble-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2rem; /* Adjust to place navigation buttons parallel to description */
}

.bubble-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.service-bubble {
  transition: transform 0.5s ease, opacity 0.5s ease;
  z-index: 2000; /* Ensure bubbles are below the navbar and chatbot */
  position: relative;
  border-radius: 50%;
  background-color: black; /* Set background color to black */
  box-shadow: inset 0 0 2rem rgba(var(--light), 1),
              inset 2rem 0 4rem rgba(var(--pink), 1),
              inset -2rem 0 6rem rgba(var(--blue), 1),
              inset 0 4rem 4rem rgba(var(--violet), 1),
              inset 0 -2rem 2rem rgba(var(--yellow), 1),
              inset 2rem -2rem 1rem rgba(var(--yellow), 1);
  cursor: pointer;
  width: 12rem;  /* Adjusted size */
  height: 12rem; /* Adjusted size */
  margin: 0 1rem;
  opacity: 0.8;
  transform: scale(0.9);
}

.service-bubble.focused {
  opacity: 1;
  transform: scale(1.1); /* Slightly larger */
  z-index: 2500; /* Ensure the focused bubble is above other elements but below navbar and chatbot */
  padding: 10px; /* Add padding */
  margin: 10px; /* Add margin */
  width: 14rem; /* Reduce size for focused bubble */
  height: 14rem; /* Reduce size for focused bubble */
}

.bubble-img {
  width: 90%; /* Increase size for logo */
  height: 90%; /* Increase size for logo */
  object-fit: contain; /* Ensure image fits within the bubble */
  border-radius: 50%;
}

.bubble-description-container {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.description-summary {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  padding: 0 1rem;
}

.description {
  font-family: 'orbitron', sans-serif;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
}

.summary {
  color: #C0C0C0;
  font-size: 1.5rem;
}

.additional-info {
  color: #C0C0C0;
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.nav-button {
  background-color: #0380ca;
  border: none;
  color: black;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  cursor: pointer;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button:hover {
  background-color: var(--blue);
  color: white;
}

@media (max-width: 768px) {
  .bubble-navigation {
    display: none;
  }

  .bubble-wrapper {
    flex-direction: column;
  }

  .service-bubble {
    width: 12rem;  /* Adjusted size */
    height: 12rem; /* Adjusted size */
    transform: scale(1.0);
  }

  .service-bubble.focused {
    width: 14rem;  /* Reduced size for focused bubble */
    height: 14rem; /* Reduced size for focused bubble */
  }

  .bubble-description-container {
    flex-direction: column;
    margin-top: 1rem;
    padding: 0 1rem; /* Add padding to ensure text is not cropped */
  }

  .description {
    font-size: 1.5rem; /* Adjust font size for mobile view */
  }

  .summary {
    font-size: 1rem; /* Adjust font size for mobile view */
  }

  .additional-info {
    font-size: 1rem; /* Adjust font size for mobile view */
  }

  .nav-button {
    margin: 0.5rem;
  }
}

.showcase-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #000000;
  border-radius: 10px;
}

.showcase-img {
  width: 50%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 1rem;
}

.showcase-content {
  color: #C0C0C0;
  font-size: 1.2rem;
  text-align: left;
  flex: 1;
}

@media (max-width: 768px) {
  .showcase-section {
    flex-direction: column;
  }

  .showcase-img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .showcase-content {
    font-size: 1rem;
  }
}
