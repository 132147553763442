.career-form-container {
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  border-color: #0380ca;
  border: 2px solid #0380ca;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2.5rem;
}

.career-form-container h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #0380ca;
}

.career-form-container p {
  margin-bottom: 20px;
  color: #fff;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

input, select {
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  width: calc(50% - 12px);
  background-color: #111;
  color: #fff;
}

input[type="file"] {
  padding: 10px;
  background-color: #111;
}

button {
  padding: 10px 20px;
  background-color: #0380ca;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #026a9f;
}


.thank-you-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh; /* Full height to center vertically */
  text-align: center;
  color: #fff;
}

.igb-logo {
  width: 450px;
  margin-bottom: 20px;
}

.thank-you-message p {
  font-size: 1.9rem;
  color: #C0C0C0;
}


.file-input {
  display: none; /* Completely hide the original file input */
}

.custom-file-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}
