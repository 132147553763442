/* Specific styles for the navbar */
.custom-navbar-container {
  width: 100%;
  background-color: var(--main-bg);
  overflow: hidden; /* Ensure no overflow */
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it is above other content */
}

.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-bg);
  width: 100%;
  padding: 1rem 2rem; /* Add padding for better spacing */
}

.custom-logo {
  flex: 1;
}

.menu {
  flex: 2;
  justify-content: center;
  display: flex;
}

.custom-navbar-contact {
  flex: 1;
  justify-content: flex-end;
}

body {
  padding-top: 80px; /* Adjust this value to the height of your navbar */
}

@media (max-width: 1024px) {
  .custom-navbar-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .custom-navbar-logo img {
    height: 40px; /* Adjust height for mobile view */
  }

  .custom-navbar-container {
    padding: 0 1rem; /* Adjust padding to ensure no side scrolling */
  }

  .menu {
    display: none; /* Hide buttons on mobile view */
  }

  .custom-navbar-contact {
    justify-content: flex-end; /* Ensure the phone number and hamburger icon are on the right */
  }
}

html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
