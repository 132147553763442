.section {
  width: 100%;
  padding: 1rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
}

.description {
  text-align: justify;
}

.image {
  max-width: 100%;
  height: auto;
}

.testimonials-container {
  margin: 2rem 0;
  border: 4px solid #34b2fd; /* Updated border color */
  border-radius: 1.5rem;
  padding: 2rem;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  margin: 0 auto;
}

.faq-container {
  margin: 2rem 0;
}

@media (min-width: 1024px) {
  .content-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title {
    text-align: left;
  }

  .description {
    text-align: left;
  }

  .testimonials-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-container {
    align-items: flex-start;
    text-align: left;
    width: 50%; /* Adjust width to control the size */
  }

  .faq-container {
    text-align: left;
  }
}


/* landing page css */

.hero {
  height: 60vh;
  min-height: 500px;
  position: relative;
  display: grid;
  place-content: center;
  overflow: hidden;
  view-timeline-name: --woof;
}

.hero-video {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center bottom;
  animation: scale 3s linear both;
  animation-timeline: view();
  animation-range: exit;
}

.hero h1 {
  isolation: isolate;
  z-index: 2; /* Ensure the h1 is above the overlay */
  font-size: 3rem;
  font-weight: 700;
  color: white;
  padding: 0 0.5rem;
  animation: fade-out 3s linear both;
  animation-timeline: --woof;
  animation-range: exit exit 30vh;
}

.overlay {
  position: absolute;
  inset: 0;
  z-index: 1; /* Ensure overlay is below the h1 text but above the video */
  background: rgba(0, 0, 0, 0.5); /* Using rgba for semi-transparent overlay */
  animation: lighten 3s linear both;
  animation-timeline: view();
  animation-range: exit;
}

.shape {
  height: 80px;
  width: 80px;
  background: linear-gradient(45deg, deeppink, fuchsia);
  margin-left: auto;
  animation: spin 3s ease-in-out both;
  animation-timeline: view();
}

@keyframes fade-out {
  to {
    opacity: 0;
    transform: translateY(60px);
  }
}

@keyframes lighten {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes scale {
  to {
    transform: scale(1.2);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    border-radius: 50px;
  }
  to {
    transform: rotate(720deg);
    border-radius: 6px;
  }
}

.content {
  padding: 3rem 0.5rem;
  max-width: 700px;
  margin: 0 auto;
}

.content h2 {
  font-size: 2.25rem;
}

.content h3 {
  font-size: 1.5rem;
  margin-top: 3rem;
}

.content p {
  font-size: 1.1rem;
  color: #3b3b3b;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
}
