html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.new-footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
}

.footer-logo img {
  width: 200px;
}

.footer-contact p {
  margin: 10px 0;
  font-size: 1.2em;
}

.footer-contact a {
  color: #0380ca;
  text-decoration: none;
}

.footer-socials {
  display: flex;
  gap: 15px;
  margin-top: 19px;
}

.footer-socials .social-icon {
  width: 40px;
  height: 40px;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px 0;
  text-align: center;
}

.footer-section {
  flex: 1;
  margin: 20px;
}

.footer-section h3 {
  color: #0380ca;
  font-size: 1.5em;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  font-size: 1.2em;
}

.footer-section ul li {
  margin: 10px 0;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-section ul li a:hover {
  /* text-decoration: underline; */
  color: #0380ca;
}

.footer-bottom {
  background-color: #000;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid #0380ca;
}

.footer-bottom p {
  margin: 0;
  font-size: 1.2em;
}

/* Mobile View */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-section {
    margin: 10px 0;
  }

  .footer-top {
    padding: 15px 0;
  }

  .footer-logo img {
    width: 120px;
  }
}

/* Tablet View */
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-links {
    justify-content: space-between;
  }

  .footer-section {
    margin: 10px 20px;
  }

  .footer-logo img {
    width: 130px;
  }
}
