/***** Global Style *****/


/***** Global Slide *****/
.slide-right, .slide-left {
  width: 100%;
}

/* **** Slide Right **** */
.slide-right {
  animation: 2s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

/***** Slide Left *****/
.slide-left {
  animation: 2s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}








   /* slide-top-to-bottom */

.slide-top-to-bottom {
  animation: slide-top-to-bottom 3s;
}

@keyframes slide-top-to-bottom {
  from {
    margin-top: -100%;
  }
  to {
    margin-top: 0%;
  }
}




/***** FadeIn Underline *****/
.line {
  border-bottom: 3px solid rgb(31, 0, 236);
  width: 200px;
  margin: auto;
}
.fade-in {
  animation: fadeIn ease 3s;
}
@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}



/* carousel */
.img_carousel {
  width: 140%;
  overflow: hidden;
  position: relative;
}

.img_carousel-container {
  display: flex;
  width: 400%; /* Adjust according to the number of items */
  animation: scroll 22s linear infinite;
}

.img_carousel-slide {
  flex: 0 0 100%; /* Each slide takes 100% of the carousel width */
  box-sizing: border-box;
}

/* Animation for auto-scrolling */
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  70% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(-300%);
  }
  95% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(0%);
  }
}

.img_carousel-slide img {
  width: auto;
  height: 26rem;
  display: block;
}
